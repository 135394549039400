import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import ClientInfo from "../../case/components/ClientInfo";
import BackButton from "../../../../../components/BackButton";
import RequestWrapper from "../../../../../components/RequestWrapper";
import { useGetSingleBusinessUsers } from "../../../../../../../hooks/business/useBusinessUsers";
import { BusinessBoxWrapper } from "../../case/components/styles";
import TableSection from "../../../../../components/table/TableSection";
import SingleUserActions from "./SingleUserActions";
import { useGetBusinessApplications } from "../../../../../../../hooks/business/useBusinessApplications";
import { BusinessQueryKey } from "../../../../../../../api/utils/types";
import { CaseOverviewTableData } from "../../dashboard/components/data";
import TablePagination from "../../../../../components/TablePagination";
import PageDropdown from "../../../../../components/table/PageDropdown";
import {
  InitialPageNo,
  PageLimit,
  perPageOptions,
} from "../../../../../components/data";
import { FC, useState } from "react";

interface Props {
  canNotStartApplication: boolean;
}

const UsersDetails: FC<Props> = ({ canNotStartApplication }) => {
  const navigate = useNavigate();
  const [pageNo, setPageNo] = useState(InitialPageNo);
  const [limitNo, setLimitNo] = useState(PageLimit);
  const [searchParams] = useSearchParams();
  const getUserId = searchParams.get(BusinessQueryKey.userId) || "";
  const { data, isLoading, isError } = useGetSingleBusinessUsers(getUserId);
  const {
    data: appData,
    isLoading: appLoading,
    isError: appError,
  } = useGetBusinessApplications({
    userId: getUserId,
    page: pageNo,
    limit: limitNo,
  });

  const handlePageClick = (params: { selected: number }) => {
    setPageNo(params.selected + 1);
  };

  const handleLimitCLick = (params: string) => {
    setLimitNo(+params);
  };

  return (
    <div>
      <BackButton onBack={() => navigate(-1)} />
      <RequestWrapper isError={isError} isLoading={isLoading} data={data}>
        <>
          {data && (
            <>
              <div className="items-start grid grid-cols-1 md:grid-cols-[1fr_0.5fr] gap-6 mt-6">
                <InfoWrapper>
                  <ClientInfo
                    name={data.userInfo.name}
                    email={data.userInfo.email}
                    phoneNumber={data.userInfo.phoneNumber}
                  />

                  <BusinessBoxWrapper>
                    <RequestWrapper
                      isError={appError}
                      isLoading={appLoading}
                      data={appData}
                    >
                      <>
                        <TableSection
                          dataItems={appData?.applicationsData}
                          tableData={CaseOverviewTableData(true)}
                          isError={appError}
                          isLoading={appLoading}
                        />

                        {appData && appData?.applicationsData?.length > 0 && (
                          <>
                            <TablePagination
                              handlePageClick={handlePageClick}
                              forcePage={pageNo - 1}
                              dataLength={appData?.applicationsData.length}
                              totalPages={appData.dataCount}
                              pageLimit={limitNo}
                            />

                            <div className="flex items-center justify-end mt-2">
                              <PageDropdown
                                option={perPageOptions}
                                onChange={(value) => {
                                  handleLimitCLick(value);
                                }}
                              />
                            </div>
                          </>
                        )}
                      </>
                    </RequestWrapper>
                  </BusinessBoxWrapper>
                </InfoWrapper>

                <SingleUserActions
                  data={data}
                  canNotStartApplication={canNotStartApplication}
                />
              </div>
            </>
          )}
        </>
      </RequestWrapper>
    </div>
  );
};

export default UsersDetails;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
