import React, { FC } from "react";

interface Props {
  bg: string;
  subText: string;
  title: string;
  icon: JSX.Element;
}

const WhyCard: FC<Props> = ({ bg, title, subText, icon }) => {
  return (
    <div className={`p-9 flex flex-col h-[470px] bg-[${bg}] gap-6`}>
      <div className="flex flex-1 w-max h-[160px]">{icon}</div>
      <div className="flex flex-col gap-6 mt-auto">
        <h5 className="text-3xl font-spartan font-[600]">{title}</h5>
        <p className="text">{subText}</p>
      </div>
    </div>
  );
};

export default WhyCard;
