import React from "react";
import Edvoy from "../../../../images/icons/edvoy-logo.svg";
import Ile from "../../../../images/icons/ile-logo.webp";

const Trusted = () => {
  return (
    <div className="py-2 bg-white border-y border-[rgba(2,118,233,0.52)]">
      <div className="flex flex-col gap-6 px-6 mx-auto max-w-7xl lg:px-8">
        <h2 className="font-semibold text-center text-gray-900 text-lg/8">
          Trusted By Forward-Thinking Enterprises and Groundbreaking
          Organizations
        </h2>
        <div className="flex items-center justify-center flex-1 gap-6">
          <div className="w-[150px] h-[150px]">
            <img
              alt="edvoy"
              src={Edvoy}
              className="object-contain w-full h-full"
            />
          </div>

          <div className="w-[150px] h-[150px]">
            <img alt="ile" src={Ile} className="object-contain w-full h-full" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Trusted;
