import { useMutation } from "@tanstack/react-query";
import { parseError } from "../../utils";
import {
  eligibilityAnalysis,
  eligibilityCountryApprovalRate,
} from "../../api/ai";

export const useEligibilityCountryApprovalRate = () => {
  return useMutation(eligibilityCountryApprovalRate, {
    onError: (error) => parseError(error),
  });
};

export const useEligibilityAnalysis = () => {
  return useMutation(eligibilityAnalysis, {
    onError: (error) => parseError(error),
  });
};
