import React from "react";
import Constaint from "../../../layout/components/Constaint";
import styled from "styled-components";
import HowCard from "./HowCard";
import academy1ImageBanner from "../../../../../images/web/academy-book.png";
import academy2ImageBanner from "../../../../../images/web/academy-select.png";
import academy3ImageBanner from "../../../../../images/web/academy-application.png";

const How = () => {
  return (
    <Wrapper>
      <Constaint>
        <div className="flex flex-col gap-10 md:mt-20">
          <h5 className="font-semibold text-center sm:text-balance sm:text-6xl font-spartan">
            How it works 
          </h5>

          <div className="flex flex-wrap gap-7">
            <HowCard
              image={academy1ImageBanner}
              bg="#E0EFFD"
              title="Book a FREE Consultation "
              subText="Schedule a free session with our study abroad advisors to discuss your academic goals and explore the best options for you. "
            />
            <HowCard
              image={academy2ImageBanner}
              bg="#F4DDFF"
              title="Select Your Preferred Course & University "
              subText="Get personalized recommendations based on your interests, budget, and career aspirations."
            />
            <HowCard
              image={academy3ImageBanner}
              bg="#7ADED0"
              title="Application Preparation & Submission Assistance "
              subText="Our team helps you prepare and submit a strong application to maximize your chance at success.  "
            />
          </div>
        </div>
      </Constaint>
    </Wrapper>
  );
};

export default How;

const Wrapper = styled.div``;
