import { CheckCircleIcon } from "@heroicons/react/20/solid";
import Constaint from "../../../layout/components/Constaint";

interface Price {
  monthly: string; // not used but keeping the interface consistent, can be removed
  annually: string; // not used but keeping the interface consistent, can be removed
  oneTime: string; // using one time price instead
}

interface Tier {
  name: string;
  id: string;
  href: string; // not used but keeping the interface consistent, can be removed
  price: Price;
  description: string; // changed to goal to match image
  goal: string;
  features: string[];
}

interface PackagesProps {
  tiers: Tier[];
}

const Packages: React.FC<PackagesProps> = ({ tiers }) => {
  return (
    <Constaint>
      <div className="bg-white py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-4xl sm:text-center">
            <h2 className="text-base/7 font-semibold text-blue-600">
              Packages
            </h2>{" "}
            {/* Changed title to Packages */}
            <p className="mt-2 text-pretty text-5xl font-semibold tracking-tight text-gray-900 sm:text-balance sm:text-6xl font-spartan">
              Choose Your Preferred Package
            </p>{" "}
            {/* Changed subtitle/title */}
          </div>
          <p className="mx-auto mt-6 max-w-2xl text-pretty text-lg font-medium text-gray-600 sm:text-center sm:text-xl/8">
            Our service packages give your international education application a
            competitive edge. From crafting compelling essays and navigating the
            I-20 process to expert U.S. visa application and interview support
            U.S. These services are designed to provide personalized support and
            enhance your overall application.
          </p>{" "}
          {/* Changed description */}
          <div className="mt-20 flow-root">
            <div className="isolate -mt-16 grid max-w-sm grid-cols-1 gap-y-16 divide-y divide-gray-100 sm:mx-auto lg:-mx-8 lg:mt-0 lg:max-w-none lg:grid-cols-3 lg:divide-x lg:divide-y-0 xl:-mx-4">
              {tiers.map((tier) => (
                <div key={tier.id} className="pt-16 lg:px-8 lg:pt-0 xl:px-14">
                  <h3
                    id={tier.id}
                    className="text-base/7 font-semibold text-gray-900"
                  >
                    {tier.name}
                  </h3>
                  <p className="mt-6 flex items-baseline gap-x-1">
                    <span className="text-5xl font-semibold tracking-tight text-gray-900">
                      ${tier.price.oneTime}
                    </span>
                    {/* <span className="text-sm/6 font-semibold text-gray-600">/month</span>  removed month text */}
                  </p>
                  {/* <p className="mt-3 text-sm/6 text-gray-500">{tier.price.annually} per month if paid annually</p> removed annual price text */}
                  <a
                    href={tier.href} // not used
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-describedby={tier.id}
                    className="mt-10 block rounded-md bg-blue-600 px-3 py-2 text-center text-sm/6 font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 cursor-pointer"
                  >
                    Select Plan{" "}
                    {/* Changed button text from Buy plan to Select Plan */}
                  </a>
                  <p className="mt-10 text-sm/6 font-semibold text-gray-900">
                    {tier.goal}
                  </p>{" "}
                  {/* changed description to goal */}
                  <ul className="mt-6 space-y-3 text-sm/6 text-gray-600">
                    {tier.features.map((feature) => (
                      <li key={feature} className="flex gap-x-3">
                        <CheckCircleIcon
                          aria-hidden="true"
                          className="h-6 w-5 flex-none text-blue-600"
                        />
                        {feature}
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Constaint>
  );
};

export default Packages;
