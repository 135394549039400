import React from "react";
import WhyCard from "./WhyCard";
import Constaint from "../../../layout/components/Constaint";
import styled from "styled-components";
import { FaBook, FaMagnifyingGlassDollar, FaGears } from "react-icons/fa6";
import { FaArrowTrendUp } from "react-icons/fa6";

const Why = () => {
  return (
    <Wrapper>
      <Constaint>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-11 md:mt-20">
          <div className="flex flex-col gap-9 md:mt-20">
            <h3 className="mt-2 text-pretty text-5xl font-semibold tracking-tight text-gray-900 sm:text-balance sm:text-6xl font-spartan">
              Why Choose SeamlessVisa? 
            </h3>
            <WhyCard
              icon={<FaBook className="w-[200px] h-full text-primaryColor" />}
              bg="#DFEFFD"
              title="Expert Guidance"
              subText="Our highly skilled advisors have years of experience helping students gain admission to top universities in the USA, Canada, United Kingdom, Australia, and Ireland.  "
            />
            <WhyCard
              icon={<FaGears className="w-[200px] h-full text-[#9746FF]" />}
              bg="#F4DDFF"
              title="Comprehensive Solution"
              subText="We assist you from the initial application to securing your student visa.  "
            />
          </div>
          <div className="flex flex-col gap-9">
            <WhyCard
              icon={
                <FaArrowTrendUp className="w-[200px] h-full text-[#30393C] " />
              }
              bg="#D7DBE5"
              title="Proven Success Rate"
              subText=" Successful admissions to prestigious institutions and high visa approval rates."
            />
            <WhyCard
              icon={
                <FaMagnifyingGlassDollar className="w-[200px] h-full text-[#00C1A3]" />
              }
              bg="#BBECE8"
              title="Affordable & Transparent Pricing"
              subText="No hidden fees – just quality services at the best rates. "
            />
          </div>
        </div>
      </Constaint>
    </Wrapper>
  );
};

export default Why;

const Wrapper = styled.div``;
